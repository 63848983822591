@import '@assets/styles/global.scss';

.dropdown {
  position: relative;
  user-select: none;

  .selected {
    @include body1;
    align-items: center;
    color: $text-body;
    cursor: pointer;
    display: flex;
    gap: 4px;
    height: 42px;
    border-radius: 12px;
  }

  .options {
    padding: 8px 6px;

    background-color: #000000;
    border-radius: 12px;
    margin-top: 8px;
    overflow: hidden;
    position: absolute;
    z-index: 100;
    right: 0;

    .option {
      @include body1;
      align-items: center;
      color: $text-white;
      cursor: pointer;
      display: flex;
      padding: 5px 8px;
      
      // Container for flag and label
      .content {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      // Gap between content and radio
      .radio {
        margin-left: 48px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #2C2C2C;
        flex-shrink: 0;
        position: relative;
        
        &.checked {
          background: #4CAF50;
          
          &:after {
            content: '';
            position: absolute;
            width: 6.5px;
            height: 6.5px;
            background: white;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      &:hover {
        background: #2C2C2C;
      }

      &.active {
        background-color: #FAF9F81A;
        border-radius: 6px;
      }
    }
  }

  &.radio {
    background-color: $icon-chevron;
    height: 20px;
    width: 20px;
  }

  &.disabled {
    opacity: 0.6;
    pointer-events: none;

    .selected {
      cursor: not-allowed;
    }
  }
} 